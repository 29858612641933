
import Vue from 'vue';
import {
  add as dateFnsAdd,
  formatISO as dateFnsFormatISO,
  set as dateFnsSet,
  startOfMonth as dateFnsStartOfMonth,
  startOfWeek as dateFnsStartOfWeek,
  sub as dateFnsSub,
} from 'date-fns';
import { gallery as galleryServices } from '@/services';
import {
  BaseLoading,
  BasePagination,
} from '@/components/BaseComponents';
import {
  GalleryBasicVideoData,
  GalleryTopbar,
} from '@/components/Gallery';

export default Vue.extend({
  name: 'ViewGallery',

  components: {
    BaseLoading,
    BasePagination,
    GalleryBasicVideoData,
    GalleryTopbar,
  },

  props: {
    encryptedGalleryId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      categoryItems: [{ text: 'all', value: 0 }] as Views.ViewGallery.CategoryItems[],
      currentPage: 1,

      loading: true,

      numberOfPagesAvailable: 0,

      orderType: 'DESC' as 'ASC' | 'DESC',

      searchValue: '',
      selectedCategory: 0,
      selectedPeriod: 'ALL' as Views.ViewGallery.Periods,

      timeOutId: 0,
      totalVideosAvailable: 0,

      videos: [] as Views.ViewGallery.Video[],
    };
  },

  watch: {
    currentPage() {
      this.getVideos(false);
    },
  },

  mounted() {
    this.getCategories();
    this.getVideos();
  },

  methods: {
    addVideoToStore(video: Views.ViewGallery.Video) {
      this.$store.dispatch('video/add', video);
    },
    async getCategories() {
      return galleryServices.getCategories(this.encryptedGalleryId)
        .then(({ data: categories }) => {
          this.categoryItems.push(
            ...categories.map((category) => ({
              text: category.name,
              value: category.id,
            })),
          );
        })
        .catch((error) => {
          console.error('ViewGallery - method: "getCategories()" ', error);

          this.showNotification('error_getting_categories', 'error');
        });
    },
    getPeriod(period: Views.ViewGallery.Periods) {
      const handleDate = (date: Date) => (
        dateFnsFormatISO(dateFnsSet(date, { hours: 0, minutes: 0, seconds: 0 }))
      );

      let beginDate: string | undefined;
      let endDate: string | undefined;

      switch (period) {
        case 'ALL': default: break;
        case 'LAST_7_DAYS':
          beginDate = handleDate(dateFnsSub(new Date(), { weeks: 1 }));
          endDate = handleDate(dateFnsAdd(new Date(), { days: 1 }));
          break;
        case 'LAST_30_DAYS':
          beginDate = handleDate(dateFnsSub(new Date(), { months: 1 }));
          endDate = handleDate(dateFnsAdd(new Date(), { days: 1 }));
          break;
        case 'THIS_MONTH':
          beginDate = handleDate(dateFnsStartOfMonth(new Date()));
          endDate = handleDate(dateFnsAdd(new Date(), { days: 1 }));
          break;
        case 'THIS_WEEK':
          beginDate = handleDate(dateFnsStartOfWeek(new Date()));
          endDate = handleDate(dateFnsAdd(new Date(), { days: 1 }));
          break;
        case 'YESTERDAY':
          beginDate = handleDate(dateFnsSub(new Date(), { days: 1 }));
          endDate = handleDate(new Date());
          break;
      }

      return { begin_date: beginDate, end_date: endDate };
    },
    async getVideosService(
      payload: Services.Gallery.GetVideos,
    ): Promise<Services.Gallery.Response.GetVideos | 'error'> {
      return galleryServices.getVideos(payload)
        .then(({ data }) => data)
        .catch((error) => {
          console.error('ViewGallery - method: "getVideos()" ', error);

          this.showNotification('error_getting_videos', 'error');

          return 'error';
        });
    },
    async getVideos(updateNumberOfPagesAvailable = true) {
      this.loading = true;

      const categoryId = this.selectedCategory > 0 ? this.selectedCategory : undefined;
      const search = this.searchValue.trim() !== '' ? this.searchValue.trim() : undefined;

      const response = await this.getVideosService({
        ...this.getPeriod(this.selectedPeriod),
        category_id: categoryId,
        encryptedGalleryId: this.encryptedGalleryId,
        page: this.currentPage,
        search,
      });

      if (response === 'error') {
        this.loading = false;

        return;
      }

      this.totalVideosAvailable = response.total;
      this.videos = response.videos;

      if (updateNumberOfPagesAvailable) {
        this.numberOfPagesAvailable = (
          Math.ceil(this.totalVideosAvailable / this.videos.length)
          || 0
        );
      }

      this.loading = false;
    },
    onChangeCategory(category: number): void {
      this.selectedCategory = category;

      this.currentPage = 1;

      this.getVideos();
    },
    onChangePeriod(period: Views.ViewGallery.Periods): void {
      this.selectedPeriod = period;

      this.currentPage = 1;

      this.getVideos();
    },
    onChangeSearch(value: string): void {
      this.currentPage = 1;
      this.searchValue = value;

      clearTimeout(this.timeOutId);

      this.timeOutId = setTimeout(() => this.getVideos(), 500);
    },
    onClickVideo(video: Views.ViewGallery.Video) {
      this.addVideoToStore(video);

      this.$router.push({ name: 'Video' });
    },
    showNotification(text: string, type: Store.Notification.Type) {
      this.$store.dispatch('notification/create', { text, type });
    },
  },
});
